<template>
  <div>
    <div
      class="card mb-3 profile-card"
      style="width: 100%"
      v-loading="loading"
      element-loading-text="Loading..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="card-header bg-light">
        <h3 class="title fw-normal mb-0 p-2">Change Password</h3>
      </div>
      <el-form ref="form" :model="updatePassword" class="log-form">
        <el-row>
          <el-col :span="24">
            <div class="card-body">
              <div class="pt-1 p-4">
                <el-form-item
                  label="Current Password"
                  class="mb-10 form-item-type-one"
                >
                  <el-input
                    :type="passwordType"
                    placeholder="Enter Old Password"
                    v-model="updatePassword.current_password"
                    autocomplete="off"
                    class="input-field"
                  ></el-input>

                  <el-button
                    type="text"
                    :style="getIsMobile ? 'left: 180px;' : 'left: 186px;'"
                    class="password-switch"
                    v-if="passwordType == 'password'"
                    @click="passwordType = 'text'"
                    >Show</el-button
                  >

                  <el-button
                    type="text"
                    :style="getIsMobile ? 'left: 180px;' : 'left: 186px;'"
                    class="password-switch"
                    v-if="passwordType != 'password'"
                    @click="passwordType = 'password'"
                    >Hide</el-button
                  >

                  <p
                    class="error"
                    v-if="
                      getUpdatePasswordErrors &&
                      getUpdatePasswordErrors.current_password
                    "
                  >
                    {{ getUpdatePasswordErrors.current_password }}
                  </p>
                </el-form-item>
                <el-form-item
                  label="New Password"
                  class="mb-1 form-item-type-one"
                >
                  <div>
                    <el-input
                      :type="passwordTypenew"
                      placeholder="Enter New Password"
                      v-model="updatePassword.new_password"
                      autocomplete="off"
                      class="input-field"
                      @input="checkPassword"
                    ></el-input>
                    <el-button
                      type="text"
                      class="password-switch"
                      :style="getIsMobile ? 'left: 180px;' : 'left: 186px;'"
                      v-if="passwordTypenew == 'password'"
                      @click="passwordTypenew = 'text'"
                      >Show</el-button
                    >
                    <el-button
                      type="text"
                      class="password-switch"
                      :style="getIsMobile ? 'left: 180px;' : 'left: 186px;'"
                      v-if="passwordTypenew != 'password'"
                      @click="passwordTypenew = 'password'"
                      >Hide</el-button
                    >
                  </div>

                  <p
                    class="error"
                    v-if="
                      getUpdatePasswordErrors &&
                      getUpdatePasswordErrors.new_password
                    "
                  >
                    {{ getUpdatePasswordErrors.new_password }}
                  </p>
                  <p class="error" v-if="errors && errors.password">
                    {{ errors.password }}
                  </p>
                </el-form-item>

                <el-form-item
                  label="Confirm Password"
                  class="mb-1 form-item-type-one"
                >
                  <div>
                    <el-input
                      :type="passwordTypeconfirm"
                      placeholder="Enter Confirm Password"
                      @input="checkConfirmPassword"
                      v-model="updatePassword.cpassword"
                      class="input-field"
                    ></el-input>
                    <el-button
                      type="text"
                      class="password-switch"
                      :style="getIsMobile ? 'left: 180px;' : 'left: 186px;'"
                      v-if="passwordTypeconfirm == 'password'"
                      @click="passwordTypeconfirm = 'text'"
                      >Show</el-button
                    >
                    <el-button
                      type="text"
                      class="password-switch"
                      :style="getIsMobile ? 'left: 180px;' : 'left: 186px;'"
                      v-if="passwordTypeconfirm != 'password'"
                      @click="passwordTypeconfirm = 'password'"
                      >Hide</el-button
                    >
                  </div>
                  <p class="error" v-if="updatePassword.cpassworderror">
                    {{ updatePassword.cpassworderror }}
                  </p>
                </el-form-item>
              </div>
            </div>
          </el-col>
        </el-row>
        <p
          class="error"
          v-if="
            getUpdatePasswordErrors && getUpdatePasswordErrors.critical_error
          "
        >
          {{ getUpdatePasswordErrors.critical_error }}
        </p>
        <p class="error" v-for="(err, index) in passwordError" :key="index">
          {{ err }}
        </p>
        <div class="card-footer bg-white">
          <el-form-item class="mb-0 p-2">
            <el-button @click="getOldData" plain>Cancel</el-button>
            <el-button
              type="primary"
              @click="onSubmit"
              >Save Changes</el-button
            >
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import MobileRelatedHelper from "@/mixins/MobileRelatedHelper";
import DocumentsHelper from "@/mixins/CompanyDocumentsHelper";
export default {
  name: "UpdatePassword",
  mixins: [MobileRelatedHelper,DocumentsHelper],
  data() {
    return {
      passwordTypenew: "password",
      passwordType: "password",
      passwordTypeconfirm: "password",
      loading: false,
      updatePassword: {
        current_password: "",
        new_password: "",
        cpassword: "",
        cpassworderror: "",
      },
      //written by sekhar
      passwordError: [],
      password_rules: [
        {
          message:
            "Must have one Uppercase(A-Z) letter,one lowercase(a-z) letter ,one number(0-9) and one symbol",
          regex:
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/,
        },
      ],
      errors: {},
    };
  },
  computed: {
    ...mapGetters("auth", [
      "getUpdatePasswordStatus",
      "getUpdatePasswordErrors",
    ]),
    ...mapGetters("navigationOpen", ["getIsMobile"]),
  },
  mounted() {
    this.getCompanyInformation();
    this.$store.commit("auth/setUpdatePasswordErrors", null, { root: true });
  },
  methods: {
    checkPassword() {
      this.errors.password = "";
      this.passwordError = [];
      if (this.updatePassword.new_password) {
        if(this.updatePassword.current_password && this.updatePassword.new_password == this.updatePassword.current_password){
          this.$message.error("Current password and new password should not be same..!")
          this.updatePassword.new_password = ""
        }
        this.password_rules.forEach((rule) => {
          if (!rule.regex.test(this.updatePassword.new_password)) {
            this.passwordError.push(rule.message);
          }
        });
        if (
          this.updatePassword.new_password.length &&
          this.updatePassword.cpassword.length
        ) {
          // this.checkConfirmPassword();
          if (
            this.updatePassword.new_password != this.updatePassword.cpassword
          ) {
            this.passwordError.push("Password miss match");
          }
        }
      } else {
        {
          this.errors.password = "Password is required";
        }
      }
    },
    checkConfirmPassword() {
      this.passwordError = [];
      // if (this.passwordError.length && this.signup.cPassword.length) {
      if (this.updatePassword.new_password != this.updatePassword.cpassword) {
        this.passwordError.push("Password miss match");
      } else {
        this.passwordError = [];
      }
    },
    //written by sekhar
    // checkPassword() {
    //   this.passwordError = [];
    //   this.password_rules.forEach((rule) => {
    //     if (!rule.regex.test(this.updatePassword.new_password)) {
    //       this.passwordError.push(rule.message);
    //     }
    //   });
    // },

    // async onChangePassword(value) {
    //   this.cpassword = value;
    //   if (
    //     this.updatePassword.current_password == this.updatePassword.new_password
    //   ) {
    //     if (this.updatePassword.new_password != this.updatePassword.cpassword) {
    //       this.updatePassword.cpassworderror =
    //         "New Password and confirm password does not match";
    //     } else {
    //       this.updatePassword.cpassworderror = "New password must be different";
    //     }
    //   } else {
    //     this.updatePassword.cpassworderror = "";
    //   }
    // },
    async onSubmit() {
      try {
        this.errors = {};
        this.checkPassword();
        if (
          !this.updatePassword.cpassword.length &&
          this.updatePassword.new_password != this.updatePassword.cpassword
        ) {
          this.passwordError.push("Password miss match");
        }
        if (
          (this.errors && this.errors.password) ||
          this.passwordError.length
        ) {
          return false;
        } else {
          this.loading = true;
          if (this.updatePassword.cpassword.length) {
            await this.$store.dispatch(
              "auth/updateUserPassword",
              this.updatePassword
            );
          }
          if (this.getUpdatePasswordStatus) {
            this.$notify.success({
              title: "Success",
              message: "User password updated successfully",
            });
            this.resetStore();
            this.$router.push({ path: "/signin" });
          } else if (
            this.updatePassword.new_password.length &&
            !this.updatePassword.cpassword.length
          ) {
            this.updatePassword.cpassworderror = "Confirm Your Password";
            //  updatePassword.current_password
          } else {
            this.$notify.error({
              title: "Error",
              message: "Error in updating User password",
            });
          }
          this.loading = false;
        }
      } catch (err) {
        console.log("onSubmit", err);
      }
    },
    getOldData() {
      this.updatePassword.current_password = "";
      this.updatePassword.new_password = "";
      this.updatePassword.cpassword = "";
      // this.$router.push({ path: "/" });
    },
    resetStore() {
      this.$store.commit("auth/resetAuthData", null, { root: true });
      this.$store.commit(
        "configurableDocuments/resetConfigurableDocuments",
        null,
        { root: true }
      );
      this.$store.commit("employeeData/reset", null, { root: true });
      this.$store.commit("employeeDocuments/reset", null, { root: true });
      // this.$store.commit("employeeDocumentAuthorizedUsers/reset");
    },
  },
};
</script>
<style lang="scss">
.log-form {
  .el-input__inner {
    //width: 40% !important;
    //padding: 0 1.5em;
    //line-height: 3.5em;
    //height: auto;
    //	font-size: 1.15em;
    //	border-color: #8692a6;
    //letter-spacing: 0.075em;
    &:hover,
    &:focus {
      border-color: #f754a2;
      background-color: #ffffff;
    }
  }
  .password-switch {
    position: absolute;
    // right: 180px;
    // top: 6%;
    margin-left : 15px !important;
    color: #f754a2;
  }
}
.error {
  color: red;
}

.profile-card{
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    transform : scale(1.0);
}
</style>
<style scoped>
.el-button.is-plain:hover {
  background: var(--primary-contrast-color);
  border-color: var(--primary-color);
  color: var(--primary-color);
}
</style>
